@import url('https://fonts.googleapis.com/css2?family=Inconsolata&display=swap');

.h-all{
    background-color: #F8FAFC;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    width: 100vw;
    font-size: 40px;
    max-width: 100vw;
}

.h-visit-container{
    padding-top: 50px;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.h-visits{
    font-size: 50px;
    font-family: 'Inconsolata', monospace;
}

.h-title{
    font-size: 90px;
}